<script setup>
import { RouterView, useRoute, useRouter } from "vue-router";
import { computed, ref } from "vue";
import { useAuthStore } from "@/stores/auth.js";

const route = useRoute();
const router = useRouter();

const drawer = ref(false);

const links = [
  { name: "Home", route: "home" },
  { name: "Categorie", route: "categories" },
  { name: "Articoli", route: "items" },
  { name: "Noleggi", route: "rentals" },
  { name: "Riepilogo", route: "summary" },
];

const linksC = computed(() => {
  if (!useAuthStore().isAuthenticated) {
    return [];
  }
  return links.map((link) => {
    let href = "";
    try {
      href = router.resolve({ name: link.route }).href;
    } catch (e) {
      console.error(e);
    }
    return {
      title: link.name,
      props: {
        active: link.route === route.name,
        to: href,
        color: "primary",
      },
    };
  });
});

const menuItems = [{ title: "Esci", action: "logout" }];

async function menuAction(action) {
  switch (action) {
    case "logout":
      try {
        await useAuthStore().logout();
      } catch {
        /* empty */
      }
      void router.push({ name: "auth-login" });
      break;
  }
}
</script>

<template>
  <v-app>
    <v-layout>
      <v-app-bar color="primary" prominent>
        <v-app-bar-nav-icon
          variant="text"
          @click.stop="drawer = !drawer"
          v-if="useAuthStore().isAuthenticated"
        />
        <v-toolbar-title>Ghislandi Ski Service</v-toolbar-title>
        <v-spacer />
        <v-menu v-if="useAuthStore().isAuthenticated">
          <template v-slot:activator="{ props }">
            <v-btn icon="$dotsVertical" variant="text" v-bind="props" />
          </template>

          <v-list :items="menuItems">
            <v-list-item
              v-for="item in menuItems"
              :key="item.action"
              @click="menuAction(item.action)"
              :title="item.title"
            />
          </v-list>
        </v-menu>
      </v-app-bar>

      <v-navigation-drawer
        v-model="drawer"
        :location="$vuetify.display.mobile ? 'bottom' : undefined"
        temporary
      >
        <v-list :items="linksC" />
      </v-navigation-drawer>

      <v-main>
        <router-view />
      </v-main>
    </v-layout>
  </v-app>
</template>
